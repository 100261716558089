<template>
  <StatisticsBox :title="title" :hint="hint">
    <div class="flex flex-grow stats">
      <div class="flex flex-column flex-center flex-grow">
        <span class="current">
          {{ usage }}
        </span>
        <div
          class="total"
          v-if="!hideTotalWhenExceeds || total < hideTotalWhenExceeds"
        >
          / {{ total }}
        </div>
      </div>
      <div
        class="flex flex-center flex-grow"
        v-if="!hideChartWhenExceeds || total < hideChartWhenExceeds"
      >
        <div
          ref="chart"
          class="chart"
          style="margin-left: -10px; width: 100%; height: 100%"
        ></div>
      </div>
    </div>
  </StatisticsBox>
</template>

<script>
import StatisticsBox from "@/components/statistics/statistics-box";
import {mapGetters} from "vuex";
// import {init} from "echarts/lib/echarts";
// import "echarts/lib/chart/bar";
// import "echarts/lib/chart/pie";

export default {
  name: "ResourceUsageChart",
  components: {
    StatisticsBox
  },
  props: {
    target: {
      type: String,
      required: true,
      validator(val) {
        return ["data", "device"].includes(val);
      }
    },
    hideChartWhenExceeds: {
      type: Number,
      required: false,
      default: () => 0
    },
    hideTotalWhenExceeds: {
      type: Number,
      required: false,
      default: () => 0
    },
    extraUsage: {
      type: Number,
      required: false,
      default: () => 0
    },
    customTitle: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      busy: true,
      chart: null,
      targetMap: {
        data: {
          usage: ["data_history_enabled_count", "data_history_disabled_count"],
          total: "maximum_data",
          hint: "available",
          title: `${this.$t("titles.data_consumption", 1)}`,
          invertPercentage: true
        },
        device: {
          usage: ["devices_third_party_count"],
          total: ["devices_hi_count", "devices_third_party_count"],
          hint: "available",
          title: "third_party_devices",
          invertPercentage: true
        }
      }
    };
  },
  computed: {
    targetOptions() {
      return this.targetMap[this.target];
    },
    usage() {
      let props = this.targetOptions.usage;
      return (
        props.reduce(
          (sum, prop) => sum + (this.contractUsage?.[prop] ?? 0),
          0
        ) + this.extraUsage
      );
    },
    total() {
      if (this.targetOptions.total instanceof Array) {
        let props = this.targetOptions.total;
        return props.reduce(
          (sum, prop) => sum + (this.contractUsage?.[prop] ?? 0),
          0
        );
      }
      return this.contract?.[this.targetOptions.total] ?? 0;
    },
    percentage() {
      let percentage = this.total ? this.usage / this.total : 0;
      // if (this.targetOptions.invertPercentage) percentage = 1 - percentage;
      return percentage;
    },
    formattedPercentage() {
      return this.percentage.toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        style: "percent"
      });
    },
    title() {
      return (
        this.customTitle || this.$tc(this.targetOptions.title ?? this.target, 2)
      );
    },
    hint() {
      return this.$t("titles.resource_chart_hint");
    },
    color() {
      const cs = [
        [0, "#008d4c"],
        [31, "#e08e0b"],
        [51, "#dd4b39"]
      ];
      var p = Math.round(this.percentage * 100);
      for (var i = cs.length - 1; i >= 0; i--) {
        if (p >= cs[i][0]) return cs[i][1];
      }
      return cs[0][0];
    },
    chartOptions() {
      let data = [{value: this.total - this.usage}, {value: this.usage}];
      if (this.targetOptions.invertPercentage) data.reverse();
      data[0].name = this.formattedPercentage;
      return {
        color: [this.color, "#eeeeee"],
        axisPointer: {
          show: false
        },
        legend: {
          show: false
        },
        silent: true,
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"],
            data,
            cursor: "auto",
            label: {
              show: true,
              position: "center",
              fontWeight: "bold",
              fontSize: 14
            }
          }
        ]
      };
    },
    ...mapGetters("user", ["contractUsage", "contract"])
  },
  watch: {
    chartOptions: {
      immediate: true,
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      if (!this.busy && this.chartOptions && this.$refs.chart && this.total) {
        if (!this.chart) {
          this.chart = window.echarts.init(this.$refs.chart);
        }
        this.chart.setOption(this.chartOptions);
      }
    }
  },
  mounted() {
    this.busy = false;
  }
};
</script>

<style scoped>
.flex {
  display: flex;
}

.flex-grow {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.current {
  font-size: 6rem;
  font-weight: 600;
  line-height: 1;
}

.stats {
  margin-top: auto;
  flex-grow: 0;
  flex-basis: 150px;
}

::v-deep .box-body {
  display: flex;
  flex-direction: column;
}
</style>
